import { Col, Container, Row } from "react-bootstrap";
import "./datos.css";
import { GasolineraCard } from "./GasolineraCard";
import { FacturacionCard } from "./FacturacionCard";
import { Menu } from "../ui/Menu";
import { Header } from "../ui/Header";
import { useTranslation } from "react-i18next";
import { VehiculosCard } from "./VehiculosCard";
import { AlertBloqueado } from "./AlertBloqueado";
import { useCallback, useEffect, useState } from "react";
import axiosApi from "../../axios/axios-api";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

const URL_GET_ESTADO_CUENTA = "cliente/estadoCuenta";

export const DatosScreen = () => {
  const { t } = useTranslation();
  const { token, flotas } = useSelector((state) => state.auth);
  const [bloqueada, setBloqueada] = useState({
    bloqueada: true,
    tipo: null,
    fecha_desbloqueo: null,
  });
  const [loadingVehicles, setLoadingVehicles] = useState(true);
  const [numVehiculos, setNumVehiculos] = useState(null);
  const [checkingStatus, setCheckingStatus] = useState(true);
  const [refreshFacturacion, setRefreshFacturacion] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location?.state?.editedFacturacion) {
      setRefreshFacturacion(true);
    }
  }, [location?.state?.editedFacturacion]);

  const fetchEstadoCuenta = useCallback(
    async (numVehiculos) => {
      if (numVehiculos === null) {
        setBloqueada({
          bloqueada: false,
          tipo: null,
          fecha_desbloqueo: null,
        });
      }
      setCheckingStatus(true);
      try {
        if (process.env.REACT_APP_URL_API && ValidateToken()) {
          const response = await axiosApi.get(URL_GET_ESTADO_CUENTA, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          });
          if (response?.data?.code === 200) {
            if (response.data.data.bloqueada === 1) {
              setBloqueada({
                bloqueada: response.data.data.bloqueada === 1,
                tipo: "deleted",
                fecha_desbloqueo: response.data.data.fecha_desbloqueo,
              });
            }
          }
          setCheckingStatus(false);
        } else {
          dispatch(startLogout("expired"));
        }
      } catch (error) {
        console.error(error);
      }
    },
    [numVehiculos]
  );

  useEffect(() => {
    if (numVehiculos === null || numVehiculos < 4 || flotas === "1") {
      setBloqueada({
        bloqueada: false,
        tipo: null,
        fecha_desbloqueo: null,
      });
      fetchEstadoCuenta(numVehiculos);
    } else {
      setBloqueada({
        bloqueada: true,
        tipo: "limited",
        fecha_desbloqueo: null,
      });
    }
  }, [numVehiculos]);

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header />
        <Container>
          <Row className="page-header d-lg-none">
            <h1>{t("datos.header.h1")}</h1>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col sm={12} md={12} lg={6}>
              <Row className="content-section">
                <VehiculosCard
                  bloqueada={bloqueada}
                  setLoadingVehicles={setLoadingVehicles}
                  setNumVehiculos={setNumVehiculos}
                  checkingStatus={checkingStatus}
                />
              </Row>
            </Col>

            <Col sm={12} md={12} lg={{ span: 5, offset: 1 }}>
              {bloqueada.bloqueada && bloqueada.tipo !== null ? (
                <Row className="mt-3">
                  <AlertBloqueado bloqueada={bloqueada} flotas={flotas} />
                </Row>
              ) : (
                <>
                  {bloqueada.bloqueada} {bloqueada.tipo}
                </>
              )}

              <Row className="mt-3">
                <GasolineraCard />
              </Row>

              <Row className="mt-3">
                <FacturacionCard refreshFacturacion={refreshFacturacion} />
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
