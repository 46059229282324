import { useCallback, useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  InputGroup,
  Navbar,
  Row,
} from "react-bootstrap";
import petroprix_logo from "../../assets/images/petroprix-logo.svg";
import login_img from "../../assets/images/login-img.svg";
import password_hide_icon from "../../assets/images/password-hide-icon.svg";
import password_show_icon from "../../assets/images/password-show-icon.svg";
import "./login.css";
import { Link } from "react-router-dom";
import Feedback from "react-bootstrap/esm/Feedback";
import "animate.css";
import warning_icon from "../../assets/images/warning-icon-yellow.svg";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { checkingAuthentication, logout, startLogin } from "../../store/auth";
import { MiniLoader } from "../ui/MiniLoader";
import { HelpInfo } from "../ui/HelpInfo";
import axiosApi from "../../axios/axios-api";

export const LoginScreen = () => {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numLogins, setNumLogins] = useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [iconShowPassword, setIconShowPassword] = useState(password_hide_icon);
  const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITEKEY;
  const [captchaValid, setCaptchaValid] = useState(null);
  const captchaRef = useRef(null);
  const [telefono, setTelefono] = useState(t("ayuda.telefono"));
  const [email, setEmail] = useState(t("ayuda.email"));

  const onChange = () => {
    if (captchaRef.current.getValue()) {
      setCaptchaValid(true);
    } else {
      setCaptchaValid(false);
    }
  };

  const onLogin = async (event) => {
    setLoading(true);
    setNumLogins(numLogins + 1);
    event.preventDefault();

    const username = document.getElementById("email-field").value;
    const password = document.getElementById("password-field").value;

    if (
      username === "" ||
      !username.includes("@") ||
      !username.includes(".") ||
      password === ""
    ) {
      setValidated(true);
      dispatch(logout({ error: null }));
      setLoading(false);
      return;
    }

    if (captchaValid) {
      dispatch(startLogin(username, password, captchaRef.current.getValue()));
      setLoading(false);
    } else {
      dispatch(logout({ error: null }));
      setCaptchaValid(false);
      setLoading(false);
    }

    captchaRef.current.reset();
  };

  useEffect(() => {
    if (status === "checking") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [status]);

  useEffect(() => {
    if (status === "checking") {
      dispatch(logout({ error: null }));
    }
  }, []);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
    showPassword
      ? setIconShowPassword(password_hide_icon)
      : setIconShowPassword(password_show_icon);
  };

  const fetchInfoContacto = useCallback(async () => {
    try {
      if (process.env.REACT_APP_URL_API) {
        const response = await axiosApi.get("infoPetroprix", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setTelefono(data.telefono);
          setEmail(data.email_informacion);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchInfoContacto();
  }, []);

  return (
    <>
      <Container className=" d-lg-none d-xl-none ">
        <Navbar
          collapseOnSelect
          expand="lg"
          className="menu px-1"
          style={{ display: "flex" }}
        >
          <Navbar.Brand href="/">
            <img
              src={petroprix_logo}
              className="d-inline-block align-top login-page-logo"
              alt={t("login.menu.text")}
            />
          </Navbar.Brand>
          <HelpInfo telefono_informacion={telefono} email={email} />
        </Navbar>
      </Container>
      <Container className="content-login-page">
        <Container className="login-header-container">
          <header className="d-none d-lg-block">
            <img
              src={petroprix_logo}
              className="d-inline-block align-left login-page-logo"
              alt={t("login.menu.text")}
            />
          </header>
          <HelpInfo
            className={"d-none d-lg-block"}
            telefono_informacion={telefono}
            email={email}
          />
        </Container>
        <Container className="login-container">
          <Row className="content-section login-section">
            <Col xs={12} lg={{ span: 12, order: 1 }}>
              <h1>
                {t("login.header.h1")}
                <span className="red-text">{t("login.header.h1.red")}</span>
              </h1>
            </Col>
            <Col
              xs={12}
              lg={{ span: 4, offset: 1, order: 3 }}
              className="d-none d-lg-block"
            >
              <Image src={login_img} fluid="true" className="mt-5" />
            </Col>
            <Col
              xs={12}
              lg={{ span: 4, offset: 2, order: 3 }}
              className="animate__animated animate__fadeInLeft "
            >
              <h2 className="mt-5">{t("login.h2")}</h2>
              {error !== null &&
                error !== "Captcha no válido" &&
                !loading &&
                numLogins > 0 && (
                  <Alert variant="danger-login">
                    <b>{error}</b>
                  </Alert>
                )}
              {error !== null &&
                error === "expired" &&
                !loading &&
                numLogins === 0 && (
                  <Alert variant="warning-login">
                    <img
                      src={warning_icon}
                      className="warning-icon-label"
                      alt={t("login.warning.sesion.expired")}
                    />
                    <b>{t("login.warning.sesion.expired")}</b>
                  </Alert>
                )}
              {(captchaValid === false ||
                (error === "Captcha no válido" &&
                  !loading &&
                  numLogins > 0)) && (
                <Alert variant="danger-login">
                  <b>{t("login.form.captcha.not-valid")}</b>
                </Alert>
              )}
              <Form
                noValidate
                validated={validated}
                className="login-form"
                autoComplete="off"
                onSubmit={onLogin}
              >
                <FloatingLabel label={t("login.form.email")} className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder={t("login.form.email")}
                    required
                    name="fakeusernameremembered"
                    id="email-field"
                  />
                  <Feedback type="invalid">
                    {t("login.form.email.invalid")}
                  </Feedback>
                </FloatingLabel>

                <FloatingLabel
                  label={t("login.form.password")}
                  className="mb-3"
                >
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder={t("login.form.password")}
                    required
                    name="fakepasswordremembered"
                    id="password-field"
                    autoComplete="off"
                  />
                  <span
                    toggle="#password-field"
                    className="toggle-password"
                    onClick={handleShowPassword}
                  >
                    <img src={iconShowPassword} className="passwordIcon" />
                  </span>
                  <Feedback type="invalid">
                    {t("login.form.password.invalid")}
                  </Feedback>
                </FloatingLabel>
                <div className="d-flex recaptcha">
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={SITE_KEY}
                    onChange={onChange}
                    required
                  />
                </div>
                <Form.Group className="mt-2 form-login-forget">
                  <Link to={`/recuperar-password`}>
                    {t("login.form.password.forget")}
                  </Link>
                </Form.Group>
                <div className="d-grid gap-2 mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    size="lg"
                    className="form-login-btn"
                    disabled={loading}
                  >
                    {loading ? <MiniLoader /> : t("login.form.btn-login")}
                  </Button>
                  <Form.Group className="mt-2 form-login-register">
                    <p>{t("login.form.p.sin-cuenta")}</p>
                    <Link to={`/registro`} className="red-text">
                      {t("login.form.btn-registrar")}
                    </Link>
                  </Form.Group>
                  <p className="login-help-text mt-3">
                    {t("login.help.text")}{" "}
                    <a
                      href={`mailto:${email}`}
                      className="login-help-text-link"
                    >
                      {email}
                    </a>
                  </p>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
