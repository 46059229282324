import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import phone_icon from "../../assets/images/phone-icon.svg";
import { formatTelefono } from "../../helpers/formatTelefono";

export const HelpInfo = (props) => {
  const { token, nombre, apellido, email_informacion, telefono_informacion } =
    useSelector((state) => state.auth);

  const { t } = useTranslation();

  const [tel, setTel] = useState(
    telefono_informacion !== null
      ? telefono_informacion
      : props.telefono_informacion !== null &&
        props.telefono_informacion !== undefined
      ? props.telefono_informacion
      : t("ayuda.telefono")
  );

  return (
    <Col lg={3} className={`header-help ${props.className}`}>
      <span className="d-none d-lg-block header-help-text">
        {t("header.help.text")}
      </span>
      <span className="header-help-phone">
        <a className="header-help-link" href={`tel:${tel}`}>
          <img
            src={phone_icon}
            alt={t("header.help.phone")}
            className="header-help-icon"
          />
          <span className="d-none d-lg-inline">{formatTelefono(tel)}</span>
        </a>
      </span>
    </Col>
  );
};
