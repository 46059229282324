import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Container,
  FloatingLabel,
  Form,
  Modal,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosApi from "../../axios/axios-api";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import Feedback from "react-bootstrap/esm/Feedback";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout, updateDataClient } from "../../store/auth";
import { MiniLoader } from "../ui/MiniLoader";

const URL_PUT_CLIENTE = "cliente";

export function ModalEditarDatos(props) {
  const { iduser } = props;
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [savingData, setSavingData] = useState(false);

  const { token, nombre, apellido, username, telefono } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();

  const [clientData, setClientData] = useState({
    nombre: nombre || "",
    apellido: apellido || "",
    telefono: telefono || "",
  });

  const handleSaveData = async (e) => {
    e.preventDefault();
    setSavingData(true);

    let nombre = document.getElementById("nombre");
    let apellido = document.getElementById("apellido");
    let telefono = document.getElementById("telefono");

    let dataValid = {
      nombre: false,
      apellido: false,
    };

    if (nombre.value === "") {
      nombre.setCustomValidity(" ");
      dataValid.nombre = false;
    } else {
      nombre.setCustomValidity("");
      dataValid.nombre = true;
    }

    if (apellido.value === "") {
      apellido.setCustomValidity(" ");
      dataValid.apellido = false;
    } else {
      apellido.setCustomValidity("");
      dataValid.apellido = true;
    }

    setValidated(true);

    if (!dataValid.nombre || !dataValid.apellido) {
      setSavingData(false);
      return;
    }

    const data = {
      nombre: nombre.value,
      apellido: apellido.value,
      telefono: telefono.value,
    };

    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.put(URL_PUT_CLIENTE, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          // cerramos el modal
          dispatch(
            updateDataClient({
              nombre: data.nombre,
              apellido: data.apellido,
              telefono: data.telefono,
            })
          );
          props.onHide();
          toast(t("datos.modal.editar-datos.form.result.ok"), {
            icon: <LogoPetroprix />,
          });
          setSavingData(false);
        } else {
          toast(response.data.message, {
            icon: <LogoPetroprix />,
          });
          setSavingData(false);
        }
      } else {
        setSavingData(false);
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
      toast(response.data.message, {
        icon: <LogoPetroprix />,
      });
      setSavingData(false);
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Container className="py-5">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("datos.modal.editar-datos.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            autoComplete="off"
            onSubmit={(e) => handleSaveData(e)}
            noValidate
            validated={validated}
          >
            <FloatingLabel
              label={t("datos.modal.editar-datos.form.usuario")}
              className="mt-3"
            >
              <Form.Control
                type="text"
                id="usuario"
                placeholder={t("datos.modal.editar-datos.form.usuario")}
                value={username}
                disabled={true}
              />
            </FloatingLabel>

            <FloatingLabel
              label={t("datos.modal.editar-datos.form.nombre")}
              className="mt-3"
            >
              <Form.Control
                type="text"
                id="nombre"
                placeholder={t("datos.modal.editar-datos.form.nombre")}
                required
                value={clientData.nombre}
                onChange={(e) =>
                  setClientData({ ...clientData, nombre: e.target.value })
                }
              />
              <Feedback type="invalid">
                {t("datos.modal.editar-datos.form.nombre.invalid")}
              </Feedback>
            </FloatingLabel>

            <FloatingLabel
              label={t("datos.modal.editar-datos.form.apellidos")}
              className="mt-3"
            >
              <Form.Control
                type="text"
                id="apellido"
                placeholder={t("datos.modal.editar-datos.form.apellidos")}
                required
                value={clientData.apellido}
                onChange={(e) =>
                  setClientData({ ...clientData, apellido: e.target.value })
                }
              />
              <Feedback type="invalid">
                {t("datos.modal.editar-datos.form.apellidos.invalid")}
              </Feedback>
            </FloatingLabel>

            <FloatingLabel
              label={t("datos.modal.editar-datos.form.telefono")}
              className="mt-3"
            >
              <Form.Control
                type="text"
                id="telefono"
                maxLength={9}
                pattern="[0-9]{9}"
                placeholder={t("datos.modal.editar-datos.form.telefono")}
                value={clientData.telefono}
                onChange={(e) =>
                  setClientData({ ...clientData, telefono: e.target.value })
                }
              />
              <Feedback type="invalid">
                {t("datos.modal.editar-datos.form.telefono.invalid")}
              </Feedback>
            </FloatingLabel>

            <div className="d-grid gap-2 mt-5">
              <Button
                variant="outline-primary"
                size="lg"
                onClick={() => props.onHide(false)}
              >
                {t("datos.modal.editar-datos.form.cancel-btn")}
              </Button>
              <Button
                variant="primary"
                size="lg"
                onClick={(e) => handleSaveData(e)}
                id="btn-modal-data-edit"
                disabled={savingData}
              >
                {savingData ? (
                  <MiniLoader />
                ) : (
                  t("datos.modal.editar-datos.form.save-btn")
                )}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Container>
    </Modal>
  );
}
