import { AppRouter } from "./routers/AppRouter";
import i18n from "./i18n";

export const PortalClientes = () => {
  // const [language, setLanguage] = useState('en')
  // i18n.changeLanguage(language)
  // console.log(i18n.language)

  return (
    <>
      <AppRouter />
    </>
  );
};
