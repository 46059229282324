import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    status: "not-authenticated", // 'not-authenticated' | 'authenticated' | 'checking'
    id: null,
    username: null,
    token: null,
    nombre: null,
    apellido: null,
    error: null,
    telefono_informacion: null,
    telefono: null,
    email_informacion: null,
  },
  reducers: {
    login: (state, action) => {
      state.status = "authenticated";
      state.id = action.payload.id;
      state.username = action.payload.username;
      state.token = action.payload.token;
      state.nombre = action.payload.nombre;
      state.apellido = action.payload.apellido;
      state.error = null;
      state.telefono_informacion = action.payload.telefono_informacion;
      state.telefono = action.payload.telefono;
      state.email_informacion = action.payload.email_informacion;
    },
    logout: (state, action) => {
      state.status = "not-authenticated";
      state.id = null;
      state.username = null;
      state.token = null;
      state.nombre = null;
      state.apellido = null;
      state.error = action.payload.error;
      state.telefono_informacion = null;
      state.telefono = null;
      state.email_informacion = null;
    },
    checkingCredentials: (state, action) => {
      state.status = "checking";
      state.error = null;
    },
    updateDataClient: (state, action) => {
      state.nombre = action.payload.nombre;
      state.apellido = action.payload.apellido;
      state.telefono = action.payload.telefono;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, checkingCredentials, updateDataClient } =
  authSlice.actions;
