import { useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MiniLoader } from "../MiniLoader";
import { useEffect } from "react";

export const SelectRut = (props) => {
  const { t } = useTranslation();
  const [rutList, setRutList] = useState(props.items || []);
  const [filtrado, setFiltrado] = useState(props.actual || []);
  const [loadingRuts, setLoadingRuts] = useState(props.loadingRuts);

  const handleSelect = (e) => {
    let filtroRuts = [];
    let ruts = document.getElementById("filter-matriculas").children;
    for (let i = 0; i < ruts.length; i++) {
      if (ruts[i].firstChild.firstChild.checked) {
        filtroRuts.push(ruts[i].firstChild.firstChild.value);
      }
    }
    props.onChange(filtroRuts);

    // Cerrar el dropdown al guardar los filtros
    document.getElementById("dropdown-autoclose-inside-matriculas").click();
  };

  useEffect(() => {
    setLoadingRuts(props.loadingRuts);
  }, [props.loadingRuts]);

  useEffect(() => {
    setRutList(props.items);
    setFiltrado(props.actual);
  }, [props.items, props.actual]);

  return (
    <>
      <Dropdown
        autoClose="outside"
        className="filter-secondary filter-matricula"
      >
        {loadingRuts && <MiniLoader />}
        {!loadingRuts &&
          rutList.length > 0 &&
          rutList[0] !== null &&
          rutList[0] !== undefined && (
            <Dropdown.Toggle
              id="dropdown-autoclose-inside-matriculas"
              className="btn-filter-secondary"
              variant={"filter-secondary"}
            >
              {t("repostajes.filtros.matriculas")}
            </Dropdown.Toggle>
          )}
        {!loadingRuts && (
          <Dropdown.Menu>
            <span id="filter-matriculas" className="filter-dropdown">
              {rutList.map((rut) => (
                <Form.Group
                  className="dropdown-item mb-0"
                  controlId={"checkbox-filter-matricula-" + rut}
                  key={"checkbox-filter-matricula-" + rut}
                >
                  <Form.Check
                    type="checkbox"
                    label={rut}
                    value={rut}
                    defaultChecked={filtrado.includes(rut)}
                  />
                </Form.Group>
              ))}
            </span>

            <Dropdown.Item className="save-filters">
              <Button variant="secondary" onClick={handleSelect}>
                {t("repostajes.filtros.btn-guardar")}
              </Button>
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
};
