import { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Header } from "../ui/Header";
import { Menu } from "../ui/Menu";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import { useDispatch, useSelector } from "react-redux";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";
import { LoaderPetroprix } from "../ui/LoaderPetroprix";
import { CuentaFacturacionCard } from "./CuentaFacturacionCard";

const URL_GET_CUENTAS_FACTURACION = "factura/cuentasFacturacion";
const LIMIT_CUENTAS_FACTURACION = 1;

export const DatosFacturacion = () => {
  const [cuentasFacturacion, setCuentasFacturacion] = useState([]);
  const [loadingCuentasFacturacion, setLoadingCuentasFacturacion] =
    useState(false);
  const [errorLoadingCuentas, setErrorLoadingCuentas] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token, nombre, apellido } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const fetchCuentasFacturacion = useCallback(async () => {
    setLoadingCuentasFacturacion(true);
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(URL_GET_CUENTAS_FACTURACION, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setCuentasFacturacion(data);
          setErrorLoadingCuentas(false);
          setLoadingCuentasFacturacion(false);
        } else {
          setCuentasFacturacion([]);
          setErrorLoadingCuentas(false);
          setLoadingCuentasFacturacion(false);
        }
      } else {
        setCuentasFacturacion([]);
        setLoadingCuentasFacturacion(false);
        setErrorLoadingCuentas(true);
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      setCuentasFacturacion([]);
      setLoadingCuentasFacturacion(false);
      setErrorLoadingCuentas(true);
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchCuentasFacturacion();
  }, [fetchCuentasFacturacion]);

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const handleNavigateFormFacturacion = (type, id = null) => {
    if (type === "new") navigate("nueva-cuenta");
    if (type === "edit" && id) navigate(`editar-cuenta/${id}`);
  };

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header name={nombre + " " + apellido} />
        <Container>
          <Col sm={12} md={8}>
            <Row
              className={`${
                cuentasFacturacion?.length < LIMIT_CUENTAS_FACTURACION
                  ? "page-header-with-button pb-2"
                  : "page-header pb-4"
              }`}
            >
              <Col sm={12} md={8}>
                <h1>
                  <span className="no-decoration" onClick={handleNavigateBack}>
                    &lt;
                  </span>
                  {t("datos.facturacion.h1")}
                </h1>
              </Col>
              <Col sm={12} md={4}>
                {!loadingCuentasFacturacion &&
                  cuentasFacturacion.length < LIMIT_CUENTAS_FACTURACION && (
                    <Button
                      variant="outline-primary-no-border"
                      onClick={() => {
                        handleNavigateFormFacturacion("new");
                      }}
                    >
                      {t("facturas.header.btn-add-datos")}
                    </Button>
                  )}
              </Col>
            </Row>
          </Col>
        </Container>
        <Container>
          <Col sm={12} md={8}>
            <Row className="content-section mb-4 ml-2">
              {loadingCuentasFacturacion ? (
                <LoaderPetroprix />
              ) : errorLoadingCuentas ? (
                <span>
                  <p>{t("error.general")}</p>
                </span>
              ) : cuentasFacturacion.length === 0 ? (
                <>
                  <Col className="facturas-cuentas-empty">
                    <p className="facturas-cuentas-empty-title">
                      {t("facturas.cuentas.empty.title")}
                    </p>
                    <p>{t("facturas.cuentas.empty.text")}</p>
                  </Col>
                </>
              ) : (
                cuentasFacturacion.map((cuenta, index) => (
                  <CuentaFacturacionCard cuenta={cuenta} key={index} />
                ))
              )}
            </Row>
          </Col>
        </Container>
      </Container>
    </>
  );
};
