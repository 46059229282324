import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import data_invoce_icon from "../../assets/images/data-invoice-icon.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";
import { useCallback, useEffect, useState } from "react";
import axiosApi from "../../axios/axios-api";
import { updateClientData } from "../../store/auth/thunks";
import { LoaderPetroprix } from "../ui/LoaderPetroprix";
import { ModalEditarDatos } from "./ModalEditarDatos";
import { formatTelefono } from "../../helpers/formatTelefono";

export const FacturacionCard = ({ refreshFacturacion }) => {
  const { token, nombre, apellido, telefono } = useSelector(
    (state) => state.auth
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [modalEditar, setModalEditar] = useState({
    show: false,
    id: null,
    nomre: null,
    apellidos: null,
    telefono: null,
  });

  const handleEditDatos = () => {
    if (ValidateToken()) {
      setModalEditar({
        show: true,
        nombre: nombre,
        apellidos: apellido,
        telefono: telefono,
      });
    } else {
      dispatch(startLogout("expired"));
    }
  };

  useEffect(() => {
    fetchDataCliente();
  }, [refreshFacturacion]);

  const fetchDataCliente = useCallback(async () => {
    setLoading(true);
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response_client = await axiosApi.get("cliente", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response_client.status === 200) {
          setLoading(false);
          dispatch(
            updateClientData({
              nombre: response_client.data.data.nombre,
              apellido: response_client.data.data.apellido,
              telefono: response_client.data.data.telefono,
            })
          );
        } else {
          setLoading(false);
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
    }
  }, [refreshFacturacion]);

  return (
    <>
      <Container className="section pt-4 mb-4 pb-3">
        <Row>
          <Col xs={8}>
            <h2>{t("datos.facturacion.card.h2")}</h2>
          </Col>
          <Col className="section-header-btn">
            <Button variant="primary" onClick={handleEditDatos}>
              {t("datos.facturacion.card.edit-btn")}
            </Button>
          </Col>
        </Row>
        <Row className="mt-5">
          {!loading ? (
            <>
              <Col xs={3} lg={2}>
                <span className="icon-bg">
                  <img
                    src={data_invoce_icon}
                    className="data-invoice-icon"
                    alt=""
                  />
                </span>
              </Col>
              <Col>
                <Row>
                  <span className="datos-nom-user">
                    {nombre?.toLowerCase()} {apellido?.toLowerCase()}
                  </span>
                </Row>
                <Row>
                  <span className="datos-dni-user">
                    {telefono !== "0" && formatTelefono(telefono, false)}
                  </span>
                </Row>
              </Col>
            </>
          ) : (
            <Col>
              <Row>
                <LoaderPetroprix type="small" position="absolute" />
              </Row>
            </Col>
          )}
        </Row>
      </Container>

      <ModalEditarDatos
        show={modalEditar.show}
        onHide={() => setModalEditar({ show: false })}
        id={modalEditar.id}
        nombre={modalEditar.nombre}
        apellido={modalEditar.apellidos}
        telefono={modalEditar.telefono}
      />
    </>
  );
};
