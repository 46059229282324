// Funcion para comprobar si el NIF es correcto haciendo una petición a la Lambda
import axiosApi from "../axios/axios-api";

export default async function checkRUT(nif, token) {
  const url = `checkRut?rut=${nif}`;

  if (process.env.REACT_APP_URL_API) {
    try {
      const response = await axiosApi.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (response?.data?.code === 200) {
        let data = {
          code: response.data.code,
          data: response.data.message,
        };
        return data;
      } else {
        let data = {
          code: response.data.code,
          data: response.data.message,
        };
        return data;
      }
    } catch (error) {
      let data = {
        code: 500,
        data: "Error en la petición",
      };
      return data;
    }
  }
}
