import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { DashboardRoutes } from "./DashboardRoutes";
import { useDispatch, useSelector } from "react-redux";
import { AuthRoutes } from "./AuthRoutes";
import { startLogout } from "../store/auth";

export const AppRouter = () => {
  // // Comprobamos si el usuario está autenticado
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.auth);
  // Recogemos la cookie con el token
  let cookieToCheck = "";

  const cookieToken = document.cookie
    .split("; ")
    .find((row) => row.startsWith("token"))
    ?.split("=")[1];

  cookieToCheck = cookieToken;

  const cookiesPetroprix = document.cookie
    .split("; ")
    .filter((row) => row.startsWith("petroprix"));

  if (cookiesPetroprix.length > 0) {
    cookiesPetroprix.forEach((cookie) => {
      cookieToCheck += cookie.split("=")[1];
    });
  }

  // Comprobamos que el token no esté caducado
  useEffect(() => {
    if (
      cookieToCheck !== "" &&
      cookieToCheck !== undefined &&
      cookieToCheck !== null &&
      cookieToCheck !== false
    ) {
      const payload = JSON.parse(atob(cookieToCheck?.split(".")[1]));
      if (payload.exp < Date.now() / 1000) {
        // Borramos la cookie
        document.cookie =
          "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        // Borramos las cookies petroprix si existen
        if (cookiesPetroprix.length > 0) {
          cookiesPetroprix.forEach((cookie) => {
            document.cookie = `${
              cookie.split("=")[0]
            }=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          });
        }
        // Cerramos la sesion
        dispatch(startLogout("expired"));
      }
    }
  }, [status]);

  return (
    <Routes>
      {status === "authenticated" ? (
        <Route path="/*" element={<DashboardRoutes />} />
      ) : (
        <Route path="/*" element={<AuthRoutes />} />
      )}

      <Route path="/*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
