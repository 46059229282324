import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Header } from "../ui/Header";
import { Menu } from "../ui/Menu";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import { toast } from "react-toastify";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import { useDispatch, useSelector } from "react-redux";
import Feedback from "react-bootstrap/esm/Feedback";
import checkRUT from "../../functions/checkRUT";
import { MiniLoader } from "../ui/MiniLoader";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";
import { LoaderPetroprix } from "../ui/LoaderPetroprix";

const URL_GET_DATA_CLIENTE = "factura/cuentaFacturacion";
const URL_POST_PUT_DATA_FACTURACION = "factura/cuentaFacturacion";
const URL_GET_REGIONS = "factura/regiones";

export const FormDatosFacturacionScreen = ({ mode }) => {
  const [clientData, setClientData] = useState({
    billingAccountId: "",
    name: "",
    lastName: "",
    rut: "",
    businessActivity: "",
    address: "",
    comunaId: "",
    regionId: "",
    type: "",
  });

  const location = useLocation();
  const id = location.state?.id;

  const { token, nombre, apellido } = useSelector((state) => state.auth);

  const [loadingClientData, setLoadingClientData] = useState(false);
  const [loadingRegions, setLoadingRegions] = useState(false);
  const [loadingComunas, setLoadingComunas] = useState(false);
  const [validatingRUT, setValidatingRUT] = useState(false);
  const [savingData, setSavingData] = useState(false);
  const [editBlocked, setEditBlocked] = useState(false);
  const [regions, setRegions] = useState([]);
  const [comunas, setComunas] = useState([]);
  const [rutValido, setRutValido] = useState(null);
  const [validated, setValidated] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: false,
    lastName: false,
    rut: false,
    businessActivity: false,
    address: false,
    comunaId: false,
    regionId: false,
    type: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchRegions = useCallback(async () => {
    setLoadingRegions(true);
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(URL_GET_REGIONS, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          setRegions(response.data.data);
          setLoadingRegions(false);
        } else {
          dispatch(startLogout("expired"));
          setLoadingRegions(false);
        }
      } else {
        dispatch(startLogout("expired"));
        setLoadingRegions(false);
      }
    } catch (error) {
      console.error(error);
      setLoadingRegions(false);
    }
  }, []);

  useEffect(() => {
    fetchRegions();
  }, [fetchRegions]);

  const fetchClientData = useCallback(async () => {
    setLoadingClientData(true);
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(URL_GET_DATA_CLIENTE + "/" + id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setClientData(data);
          if (data.rut !== "0" && data.rut !== "" && data.rut !== null) {
            setRutValido(true);
            setEditBlocked(true);
          }
          setLoadingClientData(false);
        }
      } else {
        setLoadingClientData(false);
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      setLoadingClientData(false);
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (mode === "edit" && id) {
      fetchClientData();
    }
  }, [fetchClientData]);

  const handleSave = async (e) => {
    e.preventDefault();
    setSavingData(true);

    const validateForm = {
      name: false,
      lastName: false,
      rut: false,
      businessActivity: false,
      address: false,
      comunaId: false,
      regionId: false,
      type: false,
    };

    // Validación de datos
    validateForm.name = clientData.name !== "" && clientData.name.length >= 3;
    validateForm.lastName =
      clientData.type === "Empresa"
        ? true
        : clientData.lastName !== "" && clientData.lastName.length >= 3;
    validateForm.businessActivity = clientData.businessActivity !== "";
    validateForm.address = clientData.address !== "";
    validateForm.comunaId = clientData.comunaId !== "";
    validateForm.regionId = clientData.regionId !== "";
    validateForm.type = clientData.type !== "";

    // Validación del RUT
    if (clientData.rut !== "") {
      const rutValidation = await checkRUT(clientData.rut, token);
      validateForm.rut = rutValidation.code === 200;
    }

    setFormErrors(validateForm);
    setValidated(true); // Activamos la validación del formulario

    // Verificar si hay errores
    if (Object.values(validateForm).some((value) => value === false)) {
      const campoInvalido = Object.keys(validateForm).find(
        (key) => validateForm[key] === false
      );
      setSavingData(false);
      return;
    }

    const dataSend = new FormData();
    dataSend.append("name", clientData.name);
    dataSend.append("lastName", clientData.lastName);
    dataSend.append("rut", clientData.rut);
    dataSend.append("businessActivity", clientData.businessActivity);
    dataSend.append("address", clientData.address);
    dataSend.append("comunaId", clientData.comunaId);
    dataSend.append("regionId", clientData.regionId);
    dataSend.append("type", clientData.type);

    let method = "POST";
    let endpoint = URL_POST_PUT_DATA_FACTURACION;

    if (mode === "edit") {
      method = "PUT";
      endpoint =
        URL_POST_PUT_DATA_FACTURACION + "/" + clientData.billingAccountId;
    }

    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi[method.toLowerCase()](
          endpoint,
          dataSend,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (response?.data?.code === 200) {
          toast(t("datos.facturacion.form.result-ok"), {
            icon: <LogoPetroprix />,
          });
          if (ValidateToken()) {
            setSavingData(false);
            navigate("/datos-facturacion", {
              state: {
                editedFacturacion: true,
              },
            });
          } else {
            setSavingData(false);
            dispatch(startLogout("expired"));
          }
        } else {
          setSavingData(false);
          toast(response.data.message, {
            icon: <LogoPetroprix />,
          });
        }
      } else {
        setSavingData(false);
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      setSavingData(false);
      console.error(error);
    }
  };

  const handleChangeType = (e) => {
    //Si el tipo de cuenta es empresa se bloquea el campo de apellidos
    // y el campo de nombre se convierte en razón social
    if (e.target.value === "Empresa") {
      setClientData({
        ...clientData,
        type: e.target.value,
        lastName: "",
        name: "",
      });
    } else {
      setClientData({
        ...clientData,
        type: e.target.value,
        lastName: "",
        name: "",
      });
    }
  };

  const handleChangeRegion = (e) => {
    //Buscamos las comunas de la región seleccionada en el array de regiones
    setLoadingComunas(true);
    const region = regions.find(
      (region) => region.id === parseInt(e.target.value)
    );
    setClientData({
      ...clientData,
      regionId: e.target.value,
      comunaId: "", // Reset comuna when region changes
    });
    setComunas(region.comunas);
    setLoadingComunas(false);
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  //Efecto para cuando se carguen las comunas si el cliente ya tiene una comuna, rellenamos las comunas de la region del cliente
  // y seleccionamos la comuna del cliente
  useEffect(() => {
    setComunas([]);
    setLoadingComunas(true);
    if (clientData.comunaId !== "") {
      const region = regions.find(
        (region) => region.id === parseInt(clientData.regionId)
      );
      setComunas(region.comunas);
      setLoadingComunas(false);
    }
    setLoadingComunas(false);
  }, [loadingClientData]);

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header name={nombre + " " + apellido} />
        <Container>
          <Row className="page-header pb-4">
            <h1>
              <Link className="no-decoration" onClick={handleNavigateBack}>
                &lt;
              </Link>
              {mode === "edit"
                ? t("datos.facturacion.form.h1.edit")
                : t("datos.facturacion.form.h1.new")}
            </h1>
          </Row>
        </Container>
        <Container>
          <Row className="content-section mb-4">
            {loadingClientData && mode === "edit" ? (
              <LoaderPetroprix />
            ) : (
              <Form
                className="datos-facturacion"
                autoComplete="off"
                noValidate
                validated={validated}
                onSubmit={(e) => handleSave(e)}
              >
                <Row>
                  <Col sm={12} lg={6}>
                    <FloatingLabel
                      label={t("datos.facturacion.form.tipo-cuenta.select")}
                      className="mb-3"
                    >
                      <Form.Select
                        id="tipo-cuenta"
                        placeholder={t(
                          "datos.facturacion.form.tipo-cuenta.select"
                        )}
                        defaultValue={clientData?.type || ""}
                        onChange={(e) => handleChangeType(e)}
                        isInvalid={validated && !formErrors.type}
                        isValid={validated && formErrors.type}
                        required
                        disabled={editBlocked}
                      >
                        <option value="" disabled>
                          {t("datos.facturacion.form.tipo-cuenta.select")}
                        </option>
                        <option value="Empresa">
                          {t("datos.facturacion.form.tipo-cuenta.empresa")}
                        </option>
                        <option value="Particular">
                          {t("datos.facturacion.form.tipo-cuenta.particular")}
                        </option>
                      </Form.Select>
                      <Feedback type="invalid">
                        {t("datos.facturacion.form.tipo-cuenta.invalid")}
                      </Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col sm={12} lg={6}>
                    <FloatingLabel
                      label={t("datos.facturacion.form.business-activity")}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder={t(
                          "datos.facturacion.form.business-activity"
                        )}
                        value={clientData.businessActivity}
                        onChange={(e) => {
                          setClientData({
                            ...clientData,
                            businessActivity: e.target.value,
                          });
                        }}
                        isInvalid={validated && !formErrors.businessActivity}
                        isValid={validated && formErrors.businessActivity}
                        required
                        disabled={editBlocked}
                      />
                      <Feedback type="invalid">
                        {t("datos.facturacion.form.business-activity.invalid")}
                      </Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} lg={6}>
                    <FloatingLabel
                      label={
                        clientData?.type === "Particular"
                          ? t("datos.facturacion.form.nombre")
                          : t("datos.facturacion.form.razon-social")
                      }
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder={
                          clientData?.type === "Particular"
                            ? t("datos.facturacion.form.nombre")
                            : t("datos.facturacion.form.razon-social")
                        }
                        value={clientData?.name}
                        disabled={editBlocked}
                        onChange={(e) => {
                          setClientData({
                            ...clientData,
                            name: e.target.value,
                          });
                        }}
                        isInvalid={validated && !formErrors.name}
                        isValid={validated && formErrors.name}
                        required
                        pattern=".{3,}"
                      />
                      <Form.Control.Feedback type="invalid">
                        {clientData?.type === "Particular"
                          ? t("datos.facturacion.form.nombre.invalid")
                          : t("datos.facturacion.form.razon-social.invalid")}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>

                  <Col sm={12} lg={6}>
                    <FloatingLabel
                      label={t("datos.facturacion.form.apellidos")}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder={t("datos.facturacion.form.apellidos")}
                        value={clientData?.lastName}
                        disabled={editBlocked || clientData?.type === "Empresa"}
                        onChange={(e) => {
                          setClientData({
                            ...clientData,
                            lastName: e.target.value,
                          });
                        }}
                        isInvalid={validated && !formErrors.lastName}
                        isValid={validated && formErrors.lastName}
                        required={clientData?.type === "Particular"}
                        pattern=".{3,}"
                      />
                      <Feedback type="invalid">
                        {t("datos.facturacion.form.apellidos.invalid")}
                      </Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} lg={6}>
                    <FloatingLabel
                      label={t("datos.facturacion.form.dni")}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder={t("datos.facturacion.form.dni")}
                        value={clientData?.rut !== "0" ? clientData?.rut : ""}
                        disabled={editBlocked}
                        onChange={(e) => {
                          setClientData({
                            ...clientData,
                            rut: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          if (e.target.value !== "") {
                            setValidatingRUT(true);
                            const isValidForm = validated;
                            setValidated(false);
                            e.target.setCustomValidity(" ");
                            e.target.classList.remove("is-valid");
                            e.target.classList.remove("is-invalid");
                            setFormErrors({
                              ...formErrors,
                              rut: false,
                            });
                            checkRUT(e.target.value, token).then(
                              (rutValido) => {
                                setValidatingRUT(false);
                                if (rutValido.code !== 200) {
                                  setRutValido(false);
                                  e.target.setCustomValidity(" ");
                                  e.target.classList.remove("is-valid");
                                  e.target.classList.add("is-invalid");
                                  setFormErrors({
                                    ...formErrors,
                                    rut: false,
                                  });
                                } else {
                                  setRutValido(true);
                                  e.target.setCustomValidity("");
                                  e.target.classList.remove("is-invalid");
                                  e.target.classList.add("is-valid");
                                  setFormErrors({
                                    ...formErrors,
                                    rut: true,
                                  });
                                }
                                setValidated(isValidForm);
                              }
                            );
                          }
                        }}
                        isInvalid={
                          !validatingRUT && validated && !formErrors.rut
                        }
                        isValid={!validatingRUT && validated && formErrors.rut}
                        required
                        maxLength="12"
                        minLength="6"
                      />
                      {validatingRUT && (
                        <>
                          <div className="datos-form-validating">
                            <MiniLoader />
                          </div>
                          <p className="datos-form-validating-text">
                            {t("datos.facturacion.form.validating")}
                          </p>
                        </>
                      )}
                      {!validatingRUT && (
                        <Feedback type="invalid">
                          {t("datos.facturacion.form.dni.invalid")}
                        </Feedback>
                      )}
                    </FloatingLabel>
                  </Col>

                  <Col sm={12} lg={6}>
                    <FloatingLabel
                      label={t("datos.facturacion.form.direccion")}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder={t("datos.facturacion.form.direccion")}
                        value={clientData?.address}
                        autoComplete="datos-facturacion-direccion"
                        disabled={rutValido !== true}
                        onChange={(e) => {
                          setClientData({
                            ...clientData,
                            address: e.target.value,
                          });
                        }}
                        isInvalid={validated && !formErrors.address}
                        isValid={validated && formErrors.address}
                      />
                      <Feedback type="invalid">
                        {t("datos.facturacion.form.direccion.invalid")}
                      </Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} lg={6}>
                    {loadingRegions ? (
                      <MiniLoader />
                    ) : (
                      <FloatingLabel
                        label={t("datos.facturacion.form.provincia")}
                        className="mb-3"
                      >
                        <Form.Select
                          placeholder={t("datos.facturacion.form.provincia")}
                          defaultValue={clientData.regionId || ""}
                          onChange={(e) => handleChangeRegion(e)}
                          isInvalid={validated && !formErrors.regionId}
                          isValid={validated && formErrors.regionId}
                          required
                        >
                          <option value="" disabled>
                            {t("datos.facturacion.form.provincia.select")}
                          </option>
                          {regions.map((region) => (
                            <option key={region.id} value={region.id}>
                              {region.nombre}
                            </option>
                          ))}
                        </Form.Select>

                        <Feedback type="invalid">
                          {t("datos.facturacion.form.provincia.invalid")}
                        </Feedback>
                      </FloatingLabel>
                    )}
                  </Col>

                  <Col sm={12} lg={6}>
                    {loadingComunas ? (
                      <MiniLoader />
                    ) : (
                      <FloatingLabel
                        label={t("datos.facturacion.form.localidad")}
                        className="mb-3"
                      >
                        <Form.Select
                          placeholder={t("datos.facturacion.form.localidad")}
                          defaultValue={clientData.comunaId || ""}
                          onChange={(e) => {
                            setClientData({
                              ...clientData,
                              comunaId: e.target.value,
                            });
                          }}
                          isInvalid={validated && !formErrors.comunaId}
                          isValid={validated && formErrors.comunaId}
                          required
                          disabled={
                            loadingComunas || clientData.regionId === ""
                          }
                        >
                          <option value="" disabled>
                            {t("datos.facturacion.form.localidad.select")}
                          </option>
                          {comunas.map((comuna) => (
                            <option key={comuna.id} value={comuna.id}>
                              {comuna.nombre}
                            </option>
                          ))}
                        </Form.Select>

                        <Feedback type="invalid">
                          {t("datos.facturacion.form.localidad.invalid")}
                        </Feedback>
                      </FloatingLabel>
                    )}
                  </Col>
                </Row>

                <Row>
                  <div className="d-grid gap-2 mt-5">
                    <Button
                      id="btn-edit-data-facturacion"
                      variant="primary"
                      size="lg"
                      onClick={(e) => handleSave(e)}
                      disabled={
                        savingData ||
                        validatingRUT ||
                        loadingRegions ||
                        loadingComunas
                      }
                    >
                      {savingData ? (
                        <MiniLoader />
                      ) : (
                        t("datos.facturacion.form.save-btn")
                      )}
                    </Button>
                  </div>
                </Row>
              </Form>
            )}
          </Row>
        </Container>
      </Container>
    </>
  );
};
