// Funcion para formatear un numero de telefono con el siguiente formato +XX X XXXX XXXX
//
export function formatTelefono(telefono, prefijo = true) {
  if (!telefono) return "";
  let formatted = "";
  let count = 0;
  if (prefijo) {
    for (let i = 0; i < telefono.length; i++) {
      if (count === 3 || count == 4 || count === 8) {
        formatted += " ";
      }
      formatted += telefono[i];
      count++;
    }
  } else {
    for (let i = 0; i < telefono.length; i++) {
      if (count === 1 || count === 5) {
        formatted += " ";
      }
      formatted += telefono[i];
      count++;
    }
  }
  return formatted;
}
