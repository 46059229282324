import React from "react";
import { Button, Col, InputGroup, Row } from "react-bootstrap";
import edit_icon from "../../assets/images/edit-icon.svg";
import delete_icon from "../../assets/images/delete-icon.svg";
import { EditIcon } from "../../assets/images/EditIcon";
import { DeleteIcon } from "../../assets/images/DeleteIcon";
import { useNavigate } from "react-router-dom";

export const CuentaFacturacionCard = ({ cuenta }) => {
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/datos-facturacion/editar-cuenta`, {
      state: { id: cuenta.billingAccountId },
    });
  };

  const handleDelete = () => {
    console.log("Eliminando cuenta", cuenta.billingAccountId);
  };

  return (
    <Row className="facturas-account-card mb-3">
      <Col sm={7} md={8}>
        <Row className="facturas-cuentas-info">
          {/* <Col sm={1} md={1}>
            <InputGroup.Radio
              name="principal"
              value={cuenta?.billingAccountId}
              className="facturas-check-account-default"
              // onClick={changePrincipal}
              defaultChecked={cuenta?.active === 1 ? true : false}
            />
          </Col> */}
          <Col sm={1} md={8}>
            <p className="facturas-cuentas-info-title">
              <span className="facturas-cuentas-info-name">
                {cuenta.name} {cuenta.lastName}
              </span>
              <span className="facturas-cuentas-info">{cuenta.type}</span>
            </p>

            <p className="facturas-cuentas-info">{cuenta.rut}</p>
            <p className="facturas-cuentas-info">{cuenta.businessActivity}</p>
            <p className="facturas-cuentas-info">
              {cuenta.address}, {cuenta.comuna}, {cuenta.region}
            </p>
          </Col>
        </Row>
      </Col>
      <Col sm={5} md={4} className="text-end">
        <Col className="facturas-mas-btns">
          <Button variant="outline-primary-no-border" onClick={handleEdit}>
            <EditIcon color="#db0b27" width={28} height={28} />
          </Button>
          {/* <Button variant="outline-primary-no-border" onClick={handleDelete}>
            <DeleteIcon color="#db0b27" width={28} height={28} />
          </Button> */}
        </Col>
      </Col>
    </Row>
  );
};
