import React from "react";

export const DeleteIcon = ({
  width = 18,
  height = 21,
  color = "#db0b27",
  style = {},
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <path
        d="M1 5.5H17M7 9.5V15.5M11 9.5V15.5M2 5.5L3 17.5C3 18.0304 3.21071 18.5391 3.58579 18.9142C3.96086 19.2893 4.46957 19.5 5 19.5H13C13.5304 19.5 14.0391 19.2893 14.4142 18.9142C14.7893 18.5391 15 18.0304 15 17.5L16 5.5M6 5.5V2.5C6 2.23478 6.10536 1.98043 6.29289 1.79289C6.48043 1.60536 6.73478 1.5 7 1.5H11C11.2652 1.5 11.5196 1.60536 11.7071 1.79289C11.8946 1.98043 12 2.23478 12 2.5V5.5"
        stroke={color}
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
