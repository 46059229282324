import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../ui/Header";
import { Menu } from "../ui/Menu";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import { toast } from "react-toastify";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import { useDispatch, useSelector } from "react-redux";
import Feedback from "react-bootstrap/esm/Feedback";
import checkNIF from "../../functions/checkNIF";
import { MiniLoader } from "../ui/MiniLoader";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";
import { LoaderPetroprix } from "../ui/LoaderPetroprix";

const URL_GET_DATA_CLIENTE = "cliente";
const URL_GET_CODIGOS_PAISES = "factura/codigosPaises";

export const EditarDatosFacturacion = () => {
  const [showSelectCodigoPais, setShowSelectCodigoPais] = useState(false);
  const [validated, setValidated] = useState(false);
  const [clientData, setClientData] = useState({});
  const [loadingClientData, setLoadingClientData] = useState(false);
  const [codigosPaises, setCodigosPaises] = useState([]);
  const [loadingCodigosPaises, setLoadingCodigosPaises] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token, nombre, apellido } = useSelector((state) => state.auth);
  const [editBlocked, setEditBlocked] = useState(false);
  const dispatch = useDispatch();
  const [nifValido, setNifValido] = useState(null);
  const [validatingNIF, setValidatingNIF] = useState(false);

  const fetchClientData = useCallback(async () => {
    setLoadingClientData(true);
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(URL_GET_DATA_CLIENTE, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          if (data.cliphc !== "" && data.cliphc !== 0) {
            setEditBlocked(true);
          }
          setClientData(data);
          if (data.nif !== "0" && data.nif !== "" && data.nif !== null) {
            setNifValido(true);
          }
          setLoadingClientData(false);
        }
      } else {
        setLoadingClientData(false);
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      setLoadingClientData(false);
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchClientData();
    fetchCountryCodes();
  }, [fetchClientData]);

  const fetchCountryCodes = useCallback(async () => {
    setLoadingCodigosPaises(true);
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(URL_GET_CODIGOS_PAISES, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          setCodigosPaises(response.data.data);
          setLoadingCodigosPaises(false);
        }
      } else {
        setLoadingCodigosPaises(false);
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      setLoadingCodigosPaises(false);
      console.error(error);
    }
  }, []);

  const editClient = async (e) => {
    e.preventDefault();

    let btn = document.getElementById("btn-edit-data-facturacion");
    btn.disabled = true;

    let nombre = document.getElementById("nombre");
    let apellido = document.getElementById("apellidos");
    let nif = document.getElementById("dni");
    let dir = document.getElementById("direccion");
    let localidad = document.getElementById("localidad");
    let provincia = document.getElementById("provincia");
    let cp = document.getElementById("codigo-postal");
    let telefono = document.getElementById("telefono");
    let factura = document.getElementById("factura");
    let codigoPais = document.getElementById("codigo-pais");

    // Creo un objeto con los datos del cliente y su estado de validación
    let clientDataValidate = {
      nombre: false,
      apellido: false,
      nif: false,
      dir: false,
      localidad: false,
      provincia: false,
      cp: false,
      telefono: false,
      factura: false,
      codigoPais: false,
    };

    // Validación de datos
    if (editBlocked) {
      if (nombre.value !== clientData.nombre) {
        nombre.setCustomValidity(" ");
        clientDataValidate.nombre = false;
      } else {
        nombre.setCustomValidity("");
        clientDataValidate.nombre = true;
      }
    } else {
      if (nombre.value === "" || nombre.value.length < 3) {
        nombre.setCustomValidity(" ");
        clientDataValidate.nombre = false;
      } else {
        nombre.setCustomValidity("");
        clientDataValidate.nombre = true;
      }
    }

    if (editBlocked) {
      if (apellido.value !== clientData.apellido) {
        apellido.setCustomValidity(" ");
        clientDataValidate.apellido = false;
      } else {
        apellido.setCustomValidity("");
        clientDataValidate.apellido = true;
      }
    } else {
      if (apellido.value === "" || apellido.value.length < 3) {
        apellido.setCustomValidity(" ");
        clientDataValidate.apellido = false;
      } else {
        apellido.setCustomValidity("");
        clientDataValidate.apellido = true;
      }
    }

    if (editBlocked) {
      if (codigoPais.value !== clientData.id_codigo_pais) {
        codigoPais.setCustomValidity(" ");
        clientDataValidate.codigoPais = false;
      } else {
        codigoPais.setCustomValidity("");
        clientDataValidate.codigoPais = true;
      }
    } else {
      if (codigoPais.value === "") {
        codigoPais.setCustomValidity(" ");
        clientDataValidate.codigoPais = false;
      } else {
        codigoPais.setCustomValidity("");
        clientDataValidate.codigoPais = true;
      }
    }

    if (editBlocked) {
      if (nif.value !== clientData.nif) {
        nif.setCustomValidity(" ");
        clientDataValidate.nif = false;
      } else {
        nif.setCustomValidity("");
        clientDataValidate.nif = true;
      }
    } else {
      if (nif.value === "") {
        nif.setCustomValidity(" ");
        clientDataValidate.nif = false;
      } else {
        await checkNIF(nif.value, codigoPais.value, token).then((nifValido) => {
          if (nifValido.code !== 200) {
            nif.setCustomValidity(" ");
            clientDataValidate.nif = false;
          } else {
            nif.setCustomValidity("");
            clientDataValidate.nif = true;
          }
        });
      }
    }

    if (dir.value === "" || dir.value.length < 3) {
      dir.setCustomValidity(" ");
      clientDataValidate.dir = false;
    } else {
      dir.setCustomValidity("");
      clientDataValidate.dir = true;
    }

    if (localidad.value === "" || localidad.value.length < 3) {
      localidad.setCustomValidity(" ");
      clientDataValidate.localidad = false;
    } else {
      localidad.setCustomValidity("");
      clientDataValidate.localidad = true;
    }

    if (provincia.value === "" || provincia.value.length < 3) {
      provincia.setCustomValidity(" ");
      clientDataValidate.provincia = false;
    } else {
      provincia.setCustomValidity("");
      clientDataValidate.provincia = true;
    }

    if (cp.value === "" || cp.value.length < 4 || cp.value.length > 10) {
      cp.setCustomValidity(" ");
      clientDataValidate.cp = false;
    } else {
      cp.setCustomValidity("");
      clientDataValidate.cp = true;
    }

    if (
      telefono.value === "" ||
      telefono.value.length != 9 ||
      isNaN(telefono.value) ||
      telefono.value.includes(" ")
    ) {
      telefono.setCustomValidity(" ");
      clientDataValidate.telefono = false;
    } else {
      telefono.setCustomValidity("");
      clientDataValidate.telefono = true;
    }

    if (factura.value === "") {
      factura.setCustomValidity(" ");
      clientDataValidate.factura = false;
    } else {
      factura.setCustomValidity("");
      clientDataValidate.factura = true;
      clientDataValidate.codigoPais = true;
    }

    setValidated(true);

    // Si todos los datos son válidos, se envían al servidor
    if (
      !clientDataValidate.nombre ||
      !clientDataValidate.apellido ||
      !clientDataValidate.nif ||
      !clientDataValidate.dir ||
      !clientDataValidate.localidad ||
      !clientDataValidate.provincia ||
      !clientDataValidate.cp ||
      !clientDataValidate.telefono ||
      !clientDataValidate.factura ||
      !clientDataValidate.codigoPais
    ) {
      btn.disabled = false;
      return;
    }

    const data = {
      nombre: nombre.value,
      apellido: apellido.value,
      nif: nif.value,
      dir: dir.value,
      localidad: localidad.value,
      provincia: provincia.value,
      cp: cp.value,
      factura: factura.value,
      telefono: telefono.value,
      id_codigo_pais: codigoPais.value,
    };

    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.put(URL_GET_DATA_CLIENTE, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          toast(t("datos.facturacion.form.result-ok"), {
            icon: <LogoPetroprix />,
          });
          btn.disabled = false;
          if (ValidateToken()) {
            navigate("/datos", {
              state: {
                editedFacturacion: true,
              },
            });
          } else {
            dispatch(startLogout("expired"));
          }
        } else {
          toast(response.data.message, {
            icon: <LogoPetroprix />,
          });
          btn.disabled = false;
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
      btn.disabled = false;
    }
  };

  const showCodigoPais = (clienteFactura = null) => {
    if (!loadingClientData) {
      let factura = document.getElementById("factura")?.value;
      if (factura === "Si" || clienteFactura === "Si") {
        setShowSelectCodigoPais(true);
        if (codigosPaises.length === 0) {
          fetchCountryCodes();
        }
      } else {
        setShowSelectCodigoPais(false);
      }
    }
  };

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header name={nombre + " " + apellido} />
        <Container>
          <Row className="page-header pb-4">
            <h1>
              <Link to={`/datos`} className="no-decoration">
                &lt;
              </Link>
              {t("datos.facturacion.h1")}
            </h1>
          </Row>
        </Container>
        <Container>
          <Row className="content-section mb-4">
            {loadingClientData ? (
              <LoaderPetroprix />
            ) : (
              <Form
                className="datos-facturacion"
                autoComplete="off"
                noValidate
                validated={validated}
                onSubmit={(e) => editClient(e)}
              >
                <Row>
                  <Col sm={12} lg={6}>
                    <FloatingLabel
                      label={t("datos.facturacion.form.nombre")}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        id="nombre"
                        placeholder={t("datos.facturacion.form.nombre")}
                        defaultValue={clientData?.nombre}
                        disabled={editBlocked}
                      />
                      <Feedback type="invalid">
                        {t("datos.facturacion.form.nombre.invalid")}
                      </Feedback>
                    </FloatingLabel>
                  </Col>

                  <Col sm={12} lg={6}>
                    <FloatingLabel
                      label={t("datos.facturacion.form.apellidos")}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        id="apellidos"
                        placeholder={t("datos.facturacion.form.apellidos")}
                        defaultValue={clientData?.apellido}
                        disabled={editBlocked}
                      />
                      <Feedback type="invalid">
                        {t("datos.facturacion.form.apellidos.invalid")}
                      </Feedback>
                    </FloatingLabel>
                  </Col>

                  <Col sm={12} lg={6}>
                    <FloatingLabel
                      label={
                        loadingCodigosPaises
                          ? ""
                          : t("datos.facturacion.form.codigo-pais")
                      }
                      className="mb-3"
                    >
                      {loadingCodigosPaises ? (
                        <MiniLoader />
                      ) : (
                        <Form.Select
                          aria-label="Código de país"
                          id="codigo-pais"
                          placeholder={t(
                            "datos.facturacion.form.codigo-pais.select"
                          )}
                          defaultValue={clientData?.id_codigo_pais}
                        >
                          <option value="" disabled>
                            {t("datos.facturacion.form.codigo-pais.select")}
                          </option>

                          {codigosPaises.length > 0 &&
                            codigosPaises.map((codigo) => (
                              <option
                                key={codigo.id_codigo_pais}
                                value={codigo.id_codigo_pais}
                              >
                                {codigo.pais} ({codigo.codigo})
                              </option>
                            ))}
                        </Form.Select>
                      )}
                      <Feedback type="invalid">
                        {t("datos.facturacion.form.codigo-pais.invalid")}
                      </Feedback>
                    </FloatingLabel>
                  </Col>

                  <Col sm={12} lg={6}>
                    <FloatingLabel
                      label={t("datos.facturacion.form.dni")}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        id="dni"
                        placeholder={t("datos.facturacion.form.dni")}
                        defaultValue={
                          clientData?.nif !== "0" ? clientData?.nif : ""
                        }
                        disabled={editBlocked}
                        onBlur={(e) => {
                          if (e.target.value !== "") {
                            // Mostramos el loader mientras se valida el NIF
                            setValidatingNIF(true);
                            let cod_pais =
                              document.getElementById("codigo-pais")?.value;
                            checkNIF(e.target.value, cod_pais, token).then(
                              (nifValido) => {
                                setValidatingNIF(false);
                                if (nifValido.code !== 200) {
                                  setNifValido(false);
                                  e.target.setCustomValidity(" ");
                                  e.target.classList.remove("is-valid");
                                  e.target.classList.add("is-invalid");
                                } else {
                                  setNifValido(true);
                                  e.target.setCustomValidity("");
                                  e.target.classList.remove("is-invalid");
                                  e.target.classList.add("is-valid");
                                }
                              }
                            );
                          }
                        }}
                      />
                      {validatingNIF && (
                        <>
                          <div className="datos-form-validating">
                            <MiniLoader />
                          </div>
                          <p className="datos-form-validating-text">
                            {t("datos.facturacion.form.validating")}
                          </p>
                        </>
                      )}
                      <Feedback type="invalid">
                        {t("datos.facturacion.form.dni.invalid")}
                      </Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} lg={6}>
                    <FloatingLabel
                      label={t("datos.facturacion.form.direccion")}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        id="direccion"
                        placeholder={t("datos.facturacion.form.direccion")}
                        defaultValue={clientData?.dir}
                        autoComplete="datos-facturacion-direccion"
                        disabled={nifValido !== true}
                      />
                      <Feedback type="invalid">
                        {t("datos.facturacion.form.direccion.invalid")}
                      </Feedback>
                    </FloatingLabel>
                  </Col>

                  <Col sm={12} lg={6}>
                    <FloatingLabel
                      label={t("datos.facturacion.form.localidad")}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        id="localidad"
                        placeholder={t("datos.facturacion.form.localidad")}
                        defaultValue={clientData?.localidad}
                        disabled={nifValido !== true}
                      />
                      <Feedback type="invalid">
                        {t("datos.facturacion.form.localidad.invalid")}
                      </Feedback>
                    </FloatingLabel>
                  </Col>

                  <Col sm={12} lg={6}>
                    <FloatingLabel
                      label={t("datos.facturacion.form.provincia")}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        id="provincia"
                        placeholder={t("datos.facturacion.form.provincia")}
                        defaultValue={clientData?.provincia}
                        disabled={nifValido !== true}
                      />
                      <Feedback type="invalid">
                        {t("datos.facturacion.form.provincia.invalid")}
                      </Feedback>
                    </FloatingLabel>
                  </Col>

                  <Col sm={12} lg={6}>
                    <FloatingLabel
                      label={t("datos.facturacion.form.cp")}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        id="codigo-postal"
                        maxLength="10"
                        placeholder={t("datos.facturacion.form.cp")}
                        defaultValue={
                          clientData?.cp !== "0" ? clientData?.cp : ""
                        }
                        disabled={nifValido !== true}
                      />
                      <Feedback type="invalid">
                        {t("datos.facturacion.form.cp.invalid")}
                      </Feedback>
                    </FloatingLabel>
                  </Col>

                  <Col sm={12} lg={6}>
                    <FloatingLabel
                      label={t("datos.facturacion.form.telefono")}
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        id="telefono"
                        maxLength="9"
                        placeholder={t("datos.facturacion.form.telefono")}
                        defaultValue={
                          clientData?.telefono !== "0"
                            ? clientData?.telefono
                            : ""
                        }
                        disabled={nifValido !== true}
                      />
                      <Feedback type="invalid">
                        {t("datos.facturacion.form.telefono.invalid")}
                      </Feedback>
                    </FloatingLabel>
                  </Col>

                  <Col sm={12} lg={6}>
                    <FloatingLabel
                      label={t("datos.facturacion.form.tipo-factura")}
                      className="mb-3"
                    >
                      <Form.Select
                        aria-label="Factura"
                        id="factura"
                        placeholder={t("datos.facturacion.form.tipo-factura")}
                        defaultValue={clientData?.factura}
                        disabled={nifValido !== true}
                      >
                        <option value="" disabled>
                          {t("datos.facturacion.form.tipo-factura.select")}
                        </option>
                        <option value="No">
                          {t("datos.facturacion.form.tipo-factura.no")}
                        </option>
                        <option value="Si">
                          {t("datos.facturacion.form.tipo-factura.si")}
                        </option>
                      </Form.Select>
                      <Feedback type="invalid">
                        {t("datos.facturacion.form.tipo-factura.invalid")}
                      </Feedback>
                    </FloatingLabel>
                  </Col>
                  <p className="datos-facturacion-info">
                    {t("datos.facturacion.form.info")}
                  </p>
                </Row>

                <Row>
                  <div className="d-grid gap-2 mt-5">
                    <Button
                      id="btn-edit-data-facturacion"
                      variant="primary"
                      size="lg"
                      onClick={(e) => editClient(e)}
                    >
                      {t("datos.facturacion.form.save-btn")}
                    </Button>
                  </div>
                </Row>
              </Form>
            )}
          </Row>
        </Container>
      </Container>
    </>
  );
};
