import { Routes, Route, Navigate } from "react-router-dom";
import { DatosScreen } from "../components/datos/DatosScreen";
import { GasolineraList } from "../components/datos/GasolineraList";
import { NuevoVehiculo } from "../components/datos/NuevoVehiculo";
import { FacturasScreen } from "../components/facturas/FacturasScreen";
import { Error404Screen } from "../components/feedback/Error404Screen";
import { PromocionesScreen } from "../components/promociones/PromocionesScreen";
import { RepostajesScreen } from "../components/repostajes/RepostajesScreen";
import { AyudaScreen } from "../components/ayuda/AyudaScreen";
import { DatosFacturacion } from "../components/facturas/DatosFacturacionScreen";
import { FormDatosFacturacionScreen } from "../components/facturas/FormDatosFacturacionScreen";

export const DashboardRoutes = () => {
  return (
    <Routes>
      <Route path="datos" element={<DatosScreen />} />
      <Route path="facturas" element={<FacturasScreen />} />
      <Route path="repostajes" element={<RepostajesScreen />} />
      <Route path="promociones" element={<PromocionesScreen />} />
      <Route path="nuevo-vehiculo" element={<NuevoVehiculo />} />
      <Route path="bencineras" element={<GasolineraList />} />

      <Route path="datos-facturacion" element={<DatosFacturacion />} />
      <Route
        path="datos-facturacion/nueva-cuenta"
        element={<FormDatosFacturacionScreen mode="new" />}
      />
      <Route
        path="datos-facturacion/editar-cuenta"
        element={<FormDatosFacturacionScreen mode="edit" />}
      />

      <Route path="ayuda" element={<AyudaScreen />} />
      <Route path="/*" element={<Error404Screen />} />
      <Route path="/" element={<DatosScreen />} />
      <Route path="/login" element={<Navigate to="/" />} />
    </Routes>
  );
};
