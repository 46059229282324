import { useEffect, useRef, useState } from "react";
import { Button, Container, Dropdown } from "react-bootstrap";
import logout_icon from "../../assets/images/logout-icon.svg";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { startLogout } from "../../store/auth";
import { ModalCambiarPassword } from "./ModalCambiarPassword";
import { HelpInfo } from "./HelpInfo";

export const Header = (data = { name: "Usuario" }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const { nombre, apellido, username, token } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(startLogout());
  };

  const pathname = location.pathname.split("/");
  const ruta = pathname[pathname.length - 1];

  const [modalPassword, setModalPassword] = useState({
    show: false,
    iduser: null,
  });

  return (
    <>
      <header className="d-none d-lg-block">
        <Container className="header-container">
          <HelpInfo />
          <Dropdown className="user-options" align={{ lg: "end" }}>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              <span className="username">
                {nombre?.toLowerCase() + " " + apellido?.toLowerCase()}
                <span className="user-email">{username}</span>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="header-user-menu">
              <Dropdown.Item href="/datos-facturacion">
                {t("header.user-menu.datos-facturacion")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setModalPassword({
                    show: true,
                    iduser: null,
                  })
                }
              >
                {t("header.user-menu.password")}
              </Dropdown.Item>
              <Dropdown.Divider className="hr-no-heigth" />
              <Dropdown.Item onClick={onLogout} className="logout-item">
                {t("header.user-menu.salir")}
                <Button
                  variant="light"
                  className="user-exit"
                  onClick={onLogout}
                >
                  <img
                    src={logout_icon}
                    className="user-exit-img"
                    alt={t("header.exit-icon.text")}
                  />
                </Button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Container>
      </header>

      <ModalCambiarPassword
        show={modalPassword.show}
        onHide={() => {
          setModalPassword({ show: false });
        }}
        iduser={modalPassword.iduser}
      />
    </>
  );
};
